<template>
  <div class="container">
    <h1>About Us</h1>

    <div v-html="webSettings.about">

    </div>

  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  computed:{
    webSettings() {
      return this.$store.state.auth.Settings;
    },
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
  border-bottom: 2px solid #009fe3;
  padding-bottom: 15px;
  width: max-content;
  margin: auto;
  color: #009fe3;
  font-weight: bold;
}
</style>
